import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // useNavigate 추가
import { ArtistContext } from '../ArtistContext'; // Context import
import '../css/ArtistDetail.css';

const canvasSizeToCm = {
  '1호': '22.7 cm (40 x 53)',
  '2호': '27.3 cm (40 x 60)',
  '3호': '31.8 cm (40 x 65)',
  '4호': '41.0 cm (50 x 70)',
  '5호': '45.5 cm (50 x 80)',
  '6호': '50.0 cm (50 x 90)',
  '7호': '60.0 cm (60 x 90)',
  '8호': '70.0 cm (60 x 100)',
  '9호': '80.0 cm (70 x 100)',
  '10호': '90.0 cm (70 x 120)',
};

const ArtistDetail = () => {
  const { id } = useParams(); // URL에서 작가 ID 가져오기
  const navigate = useNavigate(); // useNavigate 훅 사용
  const { artists, totalArtists } = useContext(ArtistContext); // Context에서 artists와 totalArtists 가져오기
  const [artist, setArtist] = useState(null); // 작가 정보 상태
  const [artworks, setArtworks] = useState([]); // 작품 정보 상태

  const [selectedArtworkIndex, setSelectedArtworkIndex] = useState(null); // 선택된 작품의 인덱스
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 열림 상태

  useEffect(() => {
    const selectedArtist = artists.find((a) => a.id === Number(id)); // 현재 작가 찾기
    if (selectedArtist) {
      setArtist(selectedArtist);
      fetchArtistArtworks(id); 
    } else {
      fetchArtistDetails(); 
    }
  }, [id, artists]);

  const fetchArtistDetails = async () => {
    try {
      const response = await fetch(`/api/artists/${id}`);
      const data = await response.json();
      setArtist(data);
      

      fetchArtistArtworks(id); // 작가 정보 가져온 후 작품 정보 가져오기
    } catch (error) {
      console.error('작가 정보를 불러오는 중 오류가 발생했습니다:', error);
    }
  };

  const fetchArtistArtworks = async (artistId) => {
    try {
      const response = await fetch(`/api/gallery/artist/${artistId}/artworks`);
      const data = await response.json();
      setArtworks(data);
    } catch (error) {
      console.error('작가 작품을 불러오는 중 오류가 발생했습니다:', error);
    }
  };

  const handlePreviousArtist = () => {
    const previousId = Number(id) - 1; // 이전 작가의 ID 계산
    navigate(`/artist/${previousId}`);
  };
  
  const handleNextArtist = () => {
    const nextId = Number(id) + 1; // 다음 작가의 ID 계산
    navigate(`/artist/${nextId}`);
  };

  const hasPreviousArtist = id > 1; // 작가 ID가 1보다 크면 이전 작가 있음
  const hasNextArtist = Number(id) < totalArtists; // 다음 작가는 총 작가 수보다 ID가 작아야 함

  // 목록으로 돌아가기 핸들러
  const handleBackToList = () => {
    navigate('/artistIntroduction'); 
  };

     // 작품 클릭 핸들러
     const handleArtworkClick = (index) => {
      setSelectedArtworkIndex(index);
      setIsModalOpen(true);
    };
  
    // 모달 닫기 핸들러
    const handleCloseModal = () => {
      setIsModalOpen(false);
      setSelectedArtworkIndex(null);
    };

    // 이전 작품으로 이동
    const handlePrevArtwork = () => {
      setSelectedArtworkIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    };
  
    // 다음 작품으로 이동
    const handleNextArtwork = () => {
      setSelectedArtworkIndex((prevIndex) => (prevIndex < artworks.length - 1 ? prevIndex + 1 : prevIndex));
    };
  

  if (!artist) return <p>Loading...</p>; // 로딩 중 메시지

  return (
    <div className="artist-detail-container">
      <div className="button-container">
        <button className="button" onClick={handleBackToList}>
          <i className="fas fa-list"></i> {/* 목록 아이콘 */}
        </button>
        <button className="button" onClick={handlePreviousArtist} disabled={!hasPreviousArtist}>
          <i className="fas fa-arrow-left"></i> {/* 이전 작가 화살표 아이콘 */}
        </button>
        <button className="button" onClick={handleNextArtist} disabled={!hasNextArtist}>
          <i className="fas fa-arrow-right"></i> {/* 다음 작가 화살표 아이콘 */}
        </button>
      </div>

      <h1 className="artist-title">{artist.name}</h1> {/* 작가 이름을 중앙 상단에 크게 배치 */}
      <div className="artist-profile">
        <img src={`/uploads/${artist.image_url}`} alt={artist.title} />
        <div className="artist-info">
          <p>{artist.bio}</p>
        </div>
      </div>

       {/* 모달 추가 */}
       {isModalOpen && selectedArtworkIndex !== null && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close-button" onClick={handleCloseModal}>
              &times;
            </button>
            <img
              src={`/uploads/${artworks[selectedArtworkIndex].image_url}`}
              alt={artworks[selectedArtworkIndex].title}
              className="modal-image"
            />
            <div className="modal-navigation">
              <button
                className="modal-nav-button"
                onClick={handlePrevArtwork}
                disabled={selectedArtworkIndex === 0}
              >
                &#8592;
              </button>
              <button
                className="modal-nav-button"
                onClick={handleNextArtwork}
                disabled={selectedArtworkIndex === artworks.length - 1}
              >
                &#8594;
              </button>
            </div>
          </div>
        </div>
      )}

       {/* 작품 목록 */}
       {artworks.length > 0 ? (
        <div className="artwork-grid">
          {artworks.map((artwork, index) => (
            <div
              key={artwork.id}
              className="artwork-item"
              onClick={() => handleArtworkClick(index)}
            >
              <img
                src={`/uploads/${artwork.image_url}`}
                alt={artwork.title}
                className="artwork-image"
              />
              <p>{`${artwork.canvas_size} (${canvasSizeToCm[artwork.canvas_size]}) - ${artwork.title}`}</p>
            </div>
          ))}
        </div>
      ) : (
        <p>작품이 없습니다.</p>
      )}
    </div>
  );
};

export default ArtistDetail;
