import React, { useState, useEffect } from 'react';
import '../css/Gallery.css'; // CSS 파일

function Gallery() {
  const [artworks, setArtworks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    main_theme: '',
    sub_theme: '',
    category: '',
    canvas_size: '',
    price_range: '',
  });

  const [activeFilters, setActiveFilters] = useState({}); // 선택된 필터 상태

  useEffect(() => {
    const fetchArtworks = async () => {
      let query = `/api/gallery/artworks?`;
      for (const key in filters) {
        if (filters[key]) query += `${key}=${filters[key]}&`;
      }

      try {
        const response = await fetch(query);
        if (!response.ok) throw new Error('작품을 불러오지 못했습니다.');

        const data = await response.json();
        setArtworks(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchArtworks();
  }, [filters]);

  // 필터 선택 시
  const handleFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
    setActiveFilters({ ...activeFilters, [key]: value });
  };

  // 개별 필터 삭제 시
  const handleRemoveFilter = (key) => {
    const updatedFilters = { ...filters, [key]: '' };
    setFilters(updatedFilters);
    setActiveFilters({ ...activeFilters, [key]: '' });
  };

  // 모든 필터 삭제
  const handleClearAllFilters = () => {
    setFilters({
      main_theme: '',
      sub_theme: '',
      category: '',
      canvas_size: '',
      price_range: '',
    });
    setActiveFilters({});
  };

  return (
    <div className="gallery-container">
      <h1>Art Gallery</h1>

      {/* 선택된 필터 */}
      <div className="active-filters">
        {Object.keys(activeFilters).map((key) =>
          activeFilters[key] ? (
            <span key={key} className="filter-tag">
              {activeFilters[key]} <button onClick={() => handleRemoveFilter(key)}>x</button>
            </span>
          ) : null
        )}
        {Object.values(activeFilters).some((value) => value) && (
          <button className="clear-filters" onClick={handleClearAllFilters}>전체 필터 삭제</button>
        )}
      </div>

      {/* 필터 섹션을 개별 줄로 배치 */}
      <div className="filters-container">
        {/* 메인 테마 필터 */}
        <div className="filter-group">
          <h2>메인 테마</h2>
          <div className="filter-buttons">
            <button
              className={filters.main_theme === '동양화' ? 'active' : ''}
              onClick={() => handleFilterChange('main_theme', '동양화')}
              aria-pressed={filters.main_theme === '동양화'}
            >
              동양화
            </button>
            <button
              className={filters.main_theme === '서양화' ? 'active' : ''}
              onClick={() => handleFilterChange('main_theme', '서양화')}
              aria-pressed={filters.main_theme === '서양화'}
            >
              서양화
            </button>
            <button
              className={filters.main_theme === '인물화' ? 'active' : ''}
              onClick={() => handleFilterChange('main_theme', '인물화')}
              aria-pressed={filters.main_theme === '인물화'}
            >
              인물화
            </button>
          </div>
        </div>

        {/* 서브 테마 필터 */}
        <div className="filter-group">
          <h2>서브 테마</h2>
          <div className="filter-buttons">
            <button
              className={filters.sub_theme === '인물' ? 'active' : ''}
              onClick={() => handleFilterChange('sub_theme', '인물')}
              aria-pressed={filters.sub_theme === '인물'}
            >
              인물
            </button>
            <button
              className={filters.sub_theme === '풍경' ? 'active' : ''}
              onClick={() => handleFilterChange('sub_theme', '풍경')}
              aria-pressed={filters.sub_theme === '풍경'}
            >
              풍경
            </button>
          </div>
        </div>

        {/* 사이즈 필터 */}
        <div className="filter-group">
          <h2>사이즈</h2>
          <div className="filter-buttons">
            <button
              className={filters.canvas_size === '1~5호' ? 'active' : ''}
              onClick={() => handleFilterChange('canvas_size', '1~5호')}
              aria-pressed={filters.canvas_size === '1~5호'}
            >
              1~5호
            </button>
            <button
              className={filters.canvas_size === '6~10호' ? 'active' : ''}
              onClick={() => handleFilterChange('canvas_size', '6~10호')}
              aria-pressed={filters.canvas_size === '6~10호'}
            >
              6~10호
            </button>
            <button
              className={filters.canvas_size === '11~20호' ? 'active' : ''}
              onClick={() => handleFilterChange('canvas_size', '11~20호')}
              aria-pressed={filters.canvas_size === '11~20호'}
            >
              11~20호
            </button>
          </div>
        </div>

        {/* 가격 필터 */}
        <div className="filter-group">
          <h2>구매가격</h2>
          <div className="filter-buttons">
            <button
              className={filters.price_range === '300000-500000' ? 'active' : ''}
              onClick={() => handleFilterChange('price_range', '300000-500000')}
              aria-pressed={filters.price_range === '300000-500000'}
            >
              30만~50만
            </button>
            <button
              className={filters.price_range === '500000-1000000' ? 'active' : ''}
              onClick={() => handleFilterChange('price_range', '500000-1000000')}
              aria-pressed={filters.price_range === '500000-1000000'}
            >
              50만~100만
            </button>
          </div>
        </div>
      </div>

      {/* 작품 목록 */}
      {loading ? (
        <p>로딩 중...</p>
      ) : (
        <div className="gallery-grid">
          {artworks.map((artwork, index) => (
            <div key={index} className="gallery-item">
              <img src={`http://localhost:5000/uploads/${artwork.image_url}`} alt={artwork.title} />
              <div className="overlay">
                <h2>{artwork.title}</h2>
                <p>{artwork.artist}</p>
                <p>{artwork.main_theme} - {artwork.sub_theme}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Gallery;
